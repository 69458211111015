import React, { FC } from 'react';
import { Box, Button, Tab, Tabs, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

import HeroComp from "./Hero";
import TabComp from './tab-comp';
import carImg from '../../assets/imgs/white-car.png';
import doublePhoneImg from '../../assets/imgs/doublephone.png';
import coffeeCup from '../../assets/imgs/coffee-cup.png';
import sandwitch from '../../assets/imgs/sandwitch.png';
import handWithPhone from '../../assets/imgs/hand-with-phone.png';
import playStoreIcon from '../../assets/icons/playstore-icon.svg';
import appStoreIcon from '../../assets/icons/apple-icon.svg';
import ziggySvg from '../../assets/icons/ziggy.svg';
import maleProfileImg from '../../assets/imgs/profile-img1.png';
import femaleProfileImg from '../../assets/imgs/profile-img-2.png';
import ReviewCarousel from './ReviewCarousel';
import FaqComp from './Faqs';
import Footer from '../app/Footer';
import BusinessComp from './business-comp';



interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
  }
  
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography
                
            >{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

const HomeComp = () => {
    const theme = useTheme();
    const [tabValue, setTabValue] = React.useState(0);

    const reviewSlides = [
        {
            id: 1,
            reviewerName: 'Bishop Chinedum',
            rating: 5,
            profileImg: maleProfileImg,
            content: 'This is a wonderful experience. before i could finish my bath, the rider has arrived with my food.thank you 60secs'
        },
        {
            id: 2,
            reviewerName: 'Oluwa sarah',
            rating: 5,
            profileImg: femaleProfileImg,
            content: 'This is a wonderful experience. before i could finish my bath, the rider has arrived with my food.thank you 60secs'
        },
        {
            id: 3,
            reviewerName: 'Blake Chinonso',
            rating: 5,
            profileImg: maleProfileImg,
            content: 'This is a wonderful experience. before i could finish my bath, the rider has arrived with my food.thank you 60secs'
        },
        {
            id: 4,
            reviewerName: 'Michael Ozor',
            rating: 5,
            profileImg: maleProfileImg,
            content: 'This is a wonderful experience. before i could finish my bath, the rider has arrived with my food.thank you 60secs'
        },
    ]

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    return (
        <>
            <HeroComp />

            {/* tab section */}
            <div className="mx-auto w-11/12 sm:w-10/12 md:w-9/12 lg:8/12 my-12">
                <div className="flex justify-center mb-12 mt-20">
                    <p className="text-2xl font-semibold text-center w-10/12 md:w-6/12">Your go-to platform for essential services and earning opportunities, all in one App</p>
                </div>

                <div className="my-12">
                    <Box sx={{ bgcolor: 'background.paper', width: '100%' }}>
                        <Tabs
                        value={tabValue}
                        onChange={handleChange}
                        indicatorColor="secondary"
                        textColor="inherit"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                        >
                            <Tab 
                                style={{
                                    borderRadius: '40px',
                                    backgroundColor: '#831BD5',
                                    color: '#ffffff',
                                    padding: 3,
                                }}
                                label="Customers" 
                                {...a11yProps(0)} />
                            <Tab label="Drivers" {...a11yProps(1)} />
                            <Tab label="Vendors" {...a11yProps(2)} />
                        </Tabs>
                        <TabPanel value={tabValue} index={0} dir={'left'}>
                            <TabComp />
                        </TabPanel>
                        <TabPanel value={tabValue} index={1} dir={'left'}>
                            <TabComp />
                        </TabPanel>
                        <TabPanel value={tabValue} index={2} dir={'left'}>
                            <TabComp />
                        </TabPanel>
                    </Box>
                </div>
            </div>

            <div className="mx-auto w-11/12 sm:w-11/12 md:w-10/12 lg:10/12 my-12">
                <div className="bg-gradient-to-r from-[#C37FFF] via-[#7e3cb7] to-[#5C188B] rounded-[5px]">
                    <div className="mx-auto w-[80%] grid grid-cols-1 md:grid-cols-2">
                        <div>
                            <Button
                                variant={'contained'}
                                color={'primary'}
                                style={{
                                    backgroundColor: '#ffffff',
                                    color: '#5C188B',
                                    borderRadius: '50px',
                                    border: '1px solid #ffffff',
                                    margin: '2rem 0'
                                }}
                            >
                                Did you know?
                            </Button>

                            <p className="text-3xl text-white font-semibold">Drivers earn up to $10 every day</p>
                        </div>
                        <div>
                            <img src={carImg} alt="car-img" className="" />
                        </div>
                    </div>
                </div>
            </div>

            {/* Business section */}
            <div className="mx-auto w-11/12 sm:w-11/12 md:w-10/12 lg:10/12 my-12">
                <BusinessComp />
            </div>
            {/* Business section */}


            <div className="bg-gradient-to-r from-[#4D0481] to-[#10011B] min-h-[400px] relative">
                <img src={coffeeCup} alt="coffee-cup" className="absolute bottom-0 left-0" />
                <img src={sandwitch} alt="sandwitch" className="absolute bottom-0 right-0" />
                <div className="mx-auto w-11/12 sm:w-11/12 md:w-10/12 lg:10/12 my-12">
                    <div className="grid grid-cols-1 md:grid-cols-2">
                        <div className="my-auto">
                            
                            <p className="text-4xl md:text-5xl lg:text-5xl text-white font-semibold mb-4">60secs has you covered, anytime , any day!</p>

                            <p className="text-white mt-12 mb-4">
                                We believe deeply in driving social and economic progress across the Nigeria. 
                                We use our app to connect customers to the communities that need the most support and provide them with seamless delivery platform.
                            </p>
                        </div>
                        <div className="flex justify-center">
                            <img src={doublePhoneImg} alt="car-img" className="" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="mx-auto w-11/12 sm:w-11/12 md:w-10/12 lg:10/12 my-12">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 md:gap-8">
                    <div>
                        <img src={handWithPhone} alt="hand with phone" className="rounded-[10px]" />
                    </div>
                    <div className='h-[100%]'>
                        <div className="bg-[#AC4EFE] rounded-[10px] px-8 py-24 h-[100%]">
                            <p className="text-white mb-4">Get started in Five steps</p>

                            <div className="my-8">
                                <span className="bg-[#D9D9D9] text-[#AC4EFE] rounded-full text-center py-2 px-4">
                                    1
                                </span>
                            </div>

                            <p className="text-3xl md:text-4xl lg:text-4xl text-white font-semibold my-8">
                                Download the app from Google play store or Apple store
                            </p>


                            <div className="flex justify-between gap-4 mt-12">
                                <div>
                                    <Button
                                         sx={{
                                            width: '80%',
                                            fontSize: '11px'
                                        }}
                                        variant={'contained'}
                                        color={'primary'}
                                        style={{
                                            backgroundColor: '#5C188B',
                                            color: '#ffffff',
                                            borderRadius: '5px',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'start',
                                            gap: 4,
                                            padding: '1rem',
                                            width: 'max-content'
                                        }}
                                        startIcon={
                                            <img src={appStoreIcon} alt="playstor-icon" />
                                        }
                                    >
                                        <span>Download on apple store</span>
                                    </Button>
                                </div>
                                <div>
                                    <Button
                                        sx={{
                                            width: '80%',
                                            fontSize: '11px'
                                        }}
                                        className="relative"
                                        variant={'contained'}
                                        color={'primary'}
                                        style={{
                                            backgroundColor: '#5C188B',
                                            color: '#ffffff',
                                            borderRadius: '5px',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'start',
                                            gap: 4,
                                            padding: '1rem',
                                            width: 'max-content'
                                        }}
                                        startIcon={
                                            <img src={playStoreIcon} alt="playstor-icon" />
                                        }
                                    >
                                        <span>Download on play store</span>
                                    </Button>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div className="my-12">
                    <p className="text-3xl font-semibold flex justify-center">Reviews from our users</p>
                    <div className="flex justify-center mt-4">
                        <img src={ziggySvg} alt="ziggy-svg" />
                    </div>
                </div>

                <div className="mx-auto w-11/12 sm:w-10/12 md:w-9/12 lg:8/12 my-12">
                    <ReviewCarousel 
                        items={reviewSlides} 
                        slidesToShow={3}
                        slidesToScroll={1}
                    />
                </div>
            </div>


            <div>
                <div className="my-12">
                    <p className="text-3xl font-semibold flex justify-center">FAQ and Answers</p>
                    <div className="flex justify-center mt-4">
                        <img src={ziggySvg} alt="ziggy-svg" />
                    </div>
                </div>

                <div className="mx-auto w-11/12 sm:w-10/12 md:w-9/12 lg:8/12 my-12">
                    <FaqComp />
                </div>
            </div>

            <div className="mt-40">
                <Footer />
            </div>
        </>
    )
}

export default HomeComp;