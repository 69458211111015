import { ReactNode } from "react";
import HomePage from "../pages/website/home-page";




export type RouteType = {
    path: string;
    component: ReactNode
}

const authRoutes: RouteType[] = [
    {
        path: '/',
        component: <HomePage />,
    },
    
    // {
    //     path: '/sign-up',
    //     component: <SignUP/>
    // },
    // {
    //     path: '/sign-in',
    //     component: <SignIn/>
    // },
    // {
    //     path: '/verify',
    //     component: <EmailVerificationPage />
    // },
    // {
    //     path: '/verify/:code',
    //     component: <EmailVerificationPage />
    // },
    // {
    //   path: '/forgot-password',
    //   component:<ForgotPassword />
    // },

    // {
    //     path: '*',
    //     component: <NotFoundPage/>
    // }
]

export default authRoutes;