import React, { FC, useEffect, useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ReviewComp from '../Review-comp';

export type ReviewItem = {
    id?: number;
    reviewerName: string;
    content: string;
    rating: number;
    profileImg: string;
}

type Props = {
    dot?: boolean;
    infinite?: boolean;
    speed?: number;
    slidesToShow?: number;
    slidesToScroll?: number;
    items?: ReviewItem[]
}


const ReviewCarousel: FC<Props> = ({
    dot = true,
    infinite = true,
    speed = 500,
    slidesToShow = 1,
    slidesToScroll = 1,
    items = []
}) => {

    let [settings, setSettings] = useState<any>({
        dot: dot,
        infinite: infinite,
        speed: speed,
        slidesToShow: slidesToShow,
        slidesToScroll: slidesToScroll,
        arrows: true,
        autoplay: true,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
    });

    useEffect(() => {
        setSettings({
            dot: dot,
            infinite: infinite,
            speed: speed,
            slidesToShow: slidesToShow,
            slidesToScroll: slidesToScroll
        })
    }, [dot, infinite, speed, slidesToShow, slidesToScroll])

  return (
    <div className="w-full">
        <div className="slider-container">
            <Slider {...settings}>
                {
                    items.length > 0 && items.map((item: ReviewItem, idx: number) => 
                    (<ReviewComp key={idx} item={item} />))
                }
            </Slider>
        </div>
    </div>
  );
};

export default ReviewCarousel;