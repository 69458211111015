import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import Menu, { MenuProps } from '@mui/material/Menu';
import { Button, MenuItem } from '@mui/material';


import logo from '../../../assets/imgs/logo.png';

const Navbar: FC = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <>
            <div className="bg-[#5C188B] rounded-[40px] py-2 px-8 flex justify-between gap-4">
                <div>
                    <Link to="/" className="text-white text-xl">
                        <img src={logo} width="100px" alt="logo" />
                    </Link>
                </div>

                <div className="flex justify-start gap-4">
                    <div>
                        <Link to="/" className="text-white text-xl">Home</Link>
                    </div>
                    <div>
                        <Link to="/company" className="text-white text-xl">Company</Link>
                    </div>
                    <div>
                        <Link to="/business" className="text-white text-xl">Business</Link>
                    </div>
                </div>

                <div>
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        style={{
                            backgroundColor: '#BE7F09',
                            color: '#ffffff',
                            borderRadius: '50px',
                            border: '1px solid #ffffff'
                        }}
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    >
                        Use 60secs as
                    </Button>
                    <Menu 
                        id="demo-positioned-menu"
                        aria-labelledby="basic-button"
                        open={open}
                        elevation={0}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                    >
                        <MenuItem onClick={handleClose}>Customer</MenuItem>
                        <MenuItem onClick={handleClose}>Dispatch Rider</MenuItem>
                        <MenuItem onClick={handleClose}>Driver</MenuItem>
                    </Menu>
                </div>
            </div>
        </>
    )
}

export default Navbar;