import { FC, useState } from 'react';
import { FaFacebook } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";


import { Button } from '@mui/material';

import logo from '../../../assets/imgs/logo.png';
import playStoreIcon from '../../../assets/icons/playstore-icon.svg';
import appStoreIcon from '../../../assets/icons/apple-icon.svg';
import { Link } from 'react-router-dom';


const Footer: FC = () => {

    const [email, setEmail] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    // const notify = (type: string, msg: string) => {
    //     if (type === "success") {
    //       toast.success(msg, {
    //         position: toast.POSITION.TOP_RIGHT,
    //       });
    //     }
    
    //     if (type === "error") {
    //       toast.error(msg, {
    //         position: toast.POSITION.TOP_RIGHT,
    //       });
    //     }
    // };

    const handleSubscribe = () => {
        // setLoading(true);
        const data = { subscriberEmail: email }
        // CREATE_SUBSCRIBER(data).then((res: AxiosResponse<ApiResponse>) => {
        //     const { message, success } = res.data;
        //     if(success){
        //         setLoading(false);
        //         setEmail('');
        //         notify('success', `Newsletter subscription was ${message}`);
        //     }
        // }).catch((err: any) => {
        //     setLoading(false);
        //     const { message } = err.response.data;
        //     notify('error', message);
        // })
    }

    return (
        <>
            <div className="bg-[#08000E] w-full rounded-t-[100%] min-h-[500px] pt-36 pb-8">
                <div className="mx-auto w-11/12 sm:w-11/12 md:w-10/12 lg:10/12">

                    <div className="border-white border-b-[1px] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-4">
                        <div className="pb-20 pt-8 border-white border-r-0 md:border-r-[1px] lg:border-r-[1px] border-right-[1px]">
                            <div className="mb-24">
                                <img src={logo} alt="logo" />
                            </div>

                            <div className="flex flex-col gap-6">
                                <div>
                                    <Button
                                         sx={{
                                            width: '80%',
                                            fontSize: '11px'
                                        }}
                                        variant={'contained'}
                                        color={'primary'}
                                        style={{
                                            backgroundColor: '#5C188B',
                                            color: '#ffffff',
                                            borderRadius: '5px',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'start',
                                            gap: 4,
                                            padding: '1rem',
                                        }}
                                        startIcon={
                                            <img src={appStoreIcon} alt="playstor-icon" />
                                        }
                                    >
                                        <span>Download on apple store</span>
                                    </Button>
                                </div>
                                <div>
                                    <Button
                                        sx={{
                                            width: '80%',
                                            fontSize: '11px'
                                        }}
                                        className="relative"
                                        variant={'contained'}
                                        color={'primary'}
                                        style={{
                                            backgroundColor: '#5C188B',
                                            color: '#ffffff',
                                            borderRadius: '5px',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'start',
                                            gap: 4,
                                            padding: '1rem'
                                        }}
                                        startIcon={
                                            <img src={playStoreIcon} alt="playstor-icon" />
                                        }
                                    >
                                        <span>Download on play store</span>
                                    </Button>

                                </div>
                            </div>
                        </div>

                        <div className="pt-8 border-white border-r-0 md:border-r-[1px] lg:border-r-[1px] border-right-[1px] px-8">
                            <p className="text-white text-3xl font-semibold mb-8">Company</p>

                            <ul className="list list-none">
                                <li className="list-item my-4 text-white">
                                    <Link to={'/'}>About</Link>
                                </li>
                                <li className="list-item my-4 text-white">
                                    <Link to={'/'}>FAQs</Link>
                                </li>
                                <li className="list-item my-4 text-white">
                                    <Link to={'/'}>Blog</Link>
                                </li>
                                <li className="list-item my-4 text-white">
                                    <Link to={'/'}>Contact</Link>
                                </li>
                                <li className="list-item my-4 text-white">
                                    <Link to={'/'}>Privacy Policy</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="pt-8 border-white border-r-0 md:border-r-[1px] lg:border-r-[1px] border-right-[1px] px-8">
                            <p className="text-white text-3xl font-semibold mb-8">Business</p>

                            <ul className="list list-none">
                                <li className="list-item my-4 text-white">
                                    <Link to={'/'}>Customers</Link>
                                </li>
                                <li className="list-item my-4 text-white">
                                    <Link to={'/'}>Vendors</Link>
                                </li>
                                <li className="list-item my-4 text-white">
                                    <Link to={'/'}>Riders</Link>
                                </li>
                                <li className="list-item my-4 text-white">
                                    <Link to={'/'}>Partnership</Link>
                                </li>
                            </ul>
                        </div>

                        <div className="px-8">
                            <div className="my-4">
                                <p className="text-white text-3xl font-semibold mb-8">Newsletter</p>
                            </div>

                            <div className="mb-20">
                                <p className="text-white">Stay in touch to keep up with the latest offers from us</p>
                            </div>

                            <div className="my-4">
                                <div className='flex w-full justify-between rounded-lg border-none bg-white'>
                                    <input 
                                        type="email" 
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder='enter your email' 
                                        className='w-full rounded-l-lg border-none outline-none bg-white py-3 px-5' 
                                    />
                                    <button 
                                        onClick={() => handleSubscribe()}
                                        className='rounded-r-lg text-white text-sm bg-[#5C188B] py-3 px-6 min-w-max'
                                    >
                                        {
                                            loading ? 'subscribing...' : 'Subscribe'
                                        }
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>


                    <div className="my-8 flex justify-between">
                        <div>
                            <p className="text-white">60secs @ 2024. All rights reserved.</p>
                        </div>
                        <div className="flex justify-start gap-4">
                            <div>
                                <div className="bg-white p-1 rounded-md">
                                    <FaFacebook size={16} className="text-[#08000E]" />
                                </div>
                            </div>
                            <div>
                                <div className="bg-white p-1 rounded-md">
                                    <FaInstagram size={16} className="text-[#08000E]" />
                                </div>
                            </div>
                            <div>
                                <div className="bg-white p-1 rounded-md">
                                    <FaLinkedinIn size={16} className="text-[#08000E]" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer;