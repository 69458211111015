import { RouteType } from "./auth-routes";

//pages

// import NotFoundPage from "../pages/Not-found";

const privateRoutes: RouteType[] = [
    // {
    //     path: '/users-dashboard',
    //     component:<Dashboard/>
    // },
    
    // {
    //     path: '*',
    //     component: <NotFoundPage/>
    // },
    
];

export default privateRoutes;